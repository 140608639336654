import {Link} from 'react-router-dom';

let Footer = () => {
    return (
        <div>
            <div className="bg-black1">
                <div className="container">
                    <div className="contact-info-wrap">
                        <div className="info-card style3">
                            <div className="info-card_icon"><i className="fal fa-envelope" /></div>
                            <div className="info-card_content">
                                <p className="info-card_text">Email Address</p><Link to="mailto:kasthuribobby@gmail.com" className="info-card_link">kasthuribobby@gmail.com</Link>
                            </div>
                        </div>
                        <div className="info-card style3">
                            <div className="info-card_icon"><i className="fal fa-phone" /></div>
                            <div className="info-card_content">
                                <p className="info-card_text">Phone Number</p><Link to="tel:+919505433443" className="info-card_link">(+91) 95054 33443</Link><br/>
                                <Link to="tel:+919121041315" className="info-card_link">(+91) 91210 41315</Link>
                            </div>
                        </div>
                        <div className="info-card style3">
                            <div className="info-card_icon"><i className="fal fa-location-dot" /></div>
                            <div className="info-card_content">
                                <p className="info-card_text">Our Adress</p>
                                <Link to="https://goo.gl/maps/F9UGb4m1kJSyY6G89" className="info-card_link">Rajamahendravaram, AP</Link><br/>
                                <Link to="https://maps.app.goo.gl/NAopociPUVV1ZUxE7" className="info-card_link">Nidadavolu, AP</Link>
                                {/* <p className="info-card_text">Our Adress</p><Link to="https://goo.gl/maps/F9UGb4m1kJSyY6G89" className="info-card_link">Rajamahendravaram, AP</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer-wrapper footer-layout1">
                <div className="widget-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget footer-widget">
                                    <h3 className="widget_title">About Us</h3>
                                    <div className="as-widget-about">
                                        <h6 style={{fontSize:'18px', color:'#ccc'}}><i className="far fa-map-location-dot" /> Rajahmundry</h6>
                                        <p className="footer-text">85-11-10, 1st Street, Opp: Suzuki Showroom, Srinagar, Rajamahendravaram, Andhra Pradesh - 533101</p>
                                        <h6 style={{fontSize:'18px', color:'#ccc'}}><i className="far fa-map-location-dot" /> Nidadavolu</h6>
                                        <p className="footer-text">3-1-33/4, Patimeeda, Opp.Karantaka Bank, Nidadavolu, AP. Pin:534301</p>
                                        <div className="as-social style2"><a target="_blank" href="https://www.facebook.com/KasturiCarTravels"><i className="fab fa-facebook-f" /></a> <a target="_blank" href="https://goo.gl/maps/F9UGb4m1kJSyY6G89"><i className="fab fa-google" /></a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget widget_nav_menu footer-widget">
                                    <h3 className="widget_title">Areas we serve</h3>
                                    <div className="menu-all-pages-container">
                                        <ul className="menu">
                                            <li><a href="#">Rajahmundry to Papikondalu car travels</a></li>
                                            <li><a href="#">Rajahmundry to Maredumilli car travels</a></li>
                                            <li><a href="#">Rajahmundry to Mothugudem car travels</a></li>
                                            <li><a href="#">Rajahmundry to Lammasingi car travels</a></li>
                                            <li><a href="#">Rajahmundry to Vanajangi car travels</a></li>
                                            <li><a href="#">Rajahmundry to Araku car travels</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget widget_nav_menu footer-widget">
                                    <h3 className="widget_title">Areas we serve</h3>
                                    <div className="menu-all-pages-container">
                                        <ul className="menu">
                                            <li><a href="#">Rajahmundry to Deomali car travels</a></li>
                                            <li><a href="#">Rajahmundry to Srisailam car travels</a></li>
                                            <li><a href="#">Rajahmundry to Vijayawada car travels</a></li>
                                            <li><a href="#">Rajahmundry to Annavaram car travels</a></li>
                                            <li><a href="#">Rajahmundry to  Antarvedi car travels</a></li>
                                            <li><a href="#">Rajahmundry to Muramalla car travels</a></li>
                                            <li><a href="#">Rajahmundry to Bhadrachalam car travels</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap">
                    <div className="container">
                        <p className="copyright-text">© {(new Date().getFullYear())} <a href="#">Kasturi Car Travels</a>. All Rights Reserved. <a href="https://softsolutionz.in/" target="_blank">Soft Solutionz</a></p>
                    </div>
                </div>
            </footer><a href="#" className="scrollToTop scroll-btn"><i className="far fa-arrow-up" /></a>
        </div>

    )
}

export default Footer;